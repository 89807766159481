@charset 'utf-8';

// ---------------------------------------------------------------------------
// Mixin
// ---------------------------------------------------------------------------
// ---------------------------------------------
// [urlencode URL エンコード]
// ---------------------------------------------
// http://shanabrian.com/web/sass/svg-url-encode-function.php

@function url-encode($_string) {
  $replace-map: (
    ' ': '%20',
    '"': '%22',
    '#': '%23',
    '-': '%2d',
    '.': '%2e',
    '/': '%2f',
    ':': '%3a',
    '<': '%3c',
    '=': '%3d',
    '>': '%3e',
    '{': '%7b',
    '}': '%7d'
  );

  @each $_key, $_value in $replace-map {
    $_string: str-replace($_key, $_value, $_string, true);
  }

  @return $_string;
}


// ---------------------------------------------
// [str-replace 文字列の置換]
// ---------------------------------------------
// http://shanabrian.com/web/sass/str-replace.php

@function str-replace($_substring, $_newsubstring, $_string, $_all: false) {
  $_position: str-index($_string, $_substring);

  @while $_position != null {
    $_strlen: str-length($_substring);
    $_start: str-slice($_string, 0, $_position - 1);
    $_end: str-slice($_string, $_position + $_strlen);
    $_string: $_start + $_newsubstring + $_end;

    @if $_all == true {
      $_position: str-index($_string, $_substring);
    } @else {
      $_position: null;
    }
  }

  @return $_string;
}


// ---------------------------------------------
// [pseudo-class :hover, :focus, :active の疑似クラスを設定]
// $_hover  {Boolean} :hover
// $_focus  {Boolean} :focus
// $_active {Boolean} :active
// ---------------------------------------------

@mixin pseudo-class($_hover: true, $_active: false, $_focus: true) {
  $_pseudo-bind: ();

  @if $_hover {
    $_pseudo-bind: append($_pseudo-bind, '&:hover', comma);
  }
  @if $_active {
    $_pseudo-bind: append($_pseudo-bind, '&:active', comma);
  }
  @if $_focus {
    $_pseudo-bind: append($_pseudo-bind, '&:focus', comma);
  }

  #{$_pseudo-bind} {
    @content;
  }
}


// ---------------------------------------------
// [transition CSS の transition プロパティを設定]
// $_properties      {String | List}  transition-property プロパティの値（複数の場合は配列で）
// $_duration        {Number}         transition-duration プロパティの値（単位つき）
// $_timing-function {String}         transition-timing-function プロパティの値
// $_delay           {Number}         transition-delay プロパティの値（単位つき）
// ---------------------------------------------

@mixin transition(
  $_properties,
  $_duration:        $TRANSITION_DURATION,
  $_timing-function: $TRANDSITION_TIMING_FUNCTION,
  $_delay:           null
) {
  $_transition-bind: ();

  @each $_property in $_properties {
    $_transiton: unquote('#{$_property} #{$_duration} #{$_timing-function} #{$_delay}');
    $_transition-bind: append($_transition-bind, $_transiton, comma);
  }

  transition: $_transition-bind;
  will-change: $_properties;
}


// ---------------------------------------------
// [placeholder プレースホルダーのスタイルを設定]
// $_color      {Color}   color プロパティの値
// $_opacity    {Number}  opacity プロパティの値
// $_transition {Boolean} transition プロパティを挿入するか否か
// ---------------------------------------------

@mixin placeholder(
  $_color:      $PLACEHOLDER_COLOR,
  $_opacity:    $PLACEHOLDER_OPACITY,
  $_transition: true
) {
  $_prefix-bind:
  ':placeholder-shown',          // Common
  '::-webkit-input-placeholder', // Google Chrome, Safari, Opera 15+, Android, iOS
  '::-moz-placeholder',          // Firefox 18-
  ':-ms-input-placeholder',      // IE 10+
  '::-ms-input-placeholder';     // Edge

  @each $_prefix in $_prefix-bind {
    &#{$_prefix} {
      @if $_transition == true {
        @include transition($_properties: (color, opacity));
      }
      color: $_color;
      opacity: $_opacity;
    }
  }
}


// ---------------------------------------------
// [media-query メディアクエリを設定]
// $_range            {String} メディアクエリのサポート範囲（below | above | between）
// $_breakpoint       {Number} ブレークポイント（単位つき）
// $_breakpoint-small {Number} $_range: between の際のブレークポイントの下限（単位つき）
// $_breakpoint-large {Number} $_range: between の際のブレークポイントの上限（単位つき）
// ---------------------------------------------

@mixin media-query(
  $_range:            below,
  $_breakpoint:       $MAJOR_BREAKPOINT,
  $_breakpoint-min:   null,
  $_breakpoint-max:   null
) {
  @if $_range == below {
    // ブレークポイント未満
    @media screen and (max-width: $_breakpoint - 1px) {
      @content;
    }
  } @else if $_range == above {
    // ブレークポイント以上
    @media print, screen and (min-width: $_breakpoint - 1px + 1px) {
      @content;
    }
  } @else if $_range == between {
    // ブレークポイントの間
    @media screen and (min-width: $_breakpoint-min - 1px + 1px) and (max-width: $_breakpoint-max - 1px) {
      @content;
    }
  }
}


// ---------------------------------------------
// [svg 背景画像に SVG を使用]
// $_data       {String} : SVG 画像の SVG 要素以外の要素
// $_width      {Number} : SVG viewBox の値（単位なし、初期値 100）
// $_height     {Number} : SVG viewBox の値（単位なし、初期値 100）
// $_fill       {Color}  : fill プロパティの値（初期値 null）
// ---------------------------------------------

@mixin svg(
  $_data: '',
  $_width: 100,
  $_height: 100,
  $_fill: null
) {
  $_data-bind: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + $_width + ' ' + $_height + '"';

  @if $_fill != null {
    $_data-bind: $_data-bind + ' fill="' + $_fill + '"';
  }

  $_data-bind: $_data-bind + '>' + $_data + '</svg>';

  background-image: url('data:image/svg+xml; charset=utf8, #{url-encode($_data-bind)}');
}


// ---------------------------------------------
// [layout コンテンツをカラム状に分割]
// $_breakpoint             {Number} : ブレークポイント（単位つき）
// $_breakpoint-min:        {Number} : ブレークポイント（単位つき）
// $_breakpoint-max:        {Number} : ブレークポイント（単位つき）
// $_container              {String} : Flex コンテナーのセレクタ（一つだけ。「>」は不要）
// $_item                   {String} : Flex アイテムのセレクタ（一つだけ。「>」は不要）
// $_content-width-narrow   {Number} : ブレークポイント未満時の基準となるコンテンツ幅（単位なし）
// $_content-width-medium   {Number} : ブレークポイント（min）以上・ブレークポイント（max）未満時の基準となるコンテンツ幅（単位なし）
// $_content-width-wide     {Number} : ブレークポイント以上時の基準となるコンテンツ幅（単位なし）
// $_layout-gutter-x-narrow {Number} : ブレークポイント未満時のグリッド同士の横方向の間隔（単位なし）
// $_layout-gutter-y-narrow {Number} : ブレークポイント未満時のグリッド同士の縦方向の間隔（単位なし）
// $_layout-gutter-x-medium {Number} : ブレークポイント（min）以上・ブレークポイント（max）未満時のグリッド同士の横方向の間隔（単位なし）
// $_layout-gutter-y-medium {Number} : ブレークポイント（min）以上・ブレークポイント（max）未満時のグリッド同士の縦方向の間隔（単位なし）
// $_layout-gutter-x-wide   {Number} : ブレークポイント以上時のグリッド同士の横方向の間隔（単位なし）
// $_layout-gutter-y-wide   {Number} : ブレークポイント以上時のグリッド同士の縦方向の間隔（単位なし）
// $_layout-loop            {Number} : ループ回数
// ---------------------------------------------

@mixin layout(
  $_breakpoint:              $MAJOR_BREAKPOINT,
  $_breakpoint-min:          null,
  $_breakpoint-max:          null,
  $_container:               '.inner',
  $_item:                    '.content',
  $_content-width-narrow:    280,  // px
  $_content-width-medium:    768,  // px
  $_content-width-wide:      1020, // px
  $_layout-gutter-x-narrow:  10,   // px
  $_layout-gutter-y-narrow:  10,   // px
  $_layout-gutter-x-medium:  20,   // px
  $_layout-gutter-y-medium:  20,   // px
  $_layout-gutter-x-wide:    20,   // px
  $_layout-gutter-y-wide:    20,   // px
  $_layout-loop:             4
) {
  $_breakpoint-wide: $MAJOR_BREAKPOINT;
  $_breakpoint-narrow: $MAJOR_BREAKPOINT;

  // Wide Viewport
  @if $_breakpoint-max {
    $_breakpoint-wide: $_breakpoint-max;
  } @else {
    $_breakpoint-wide: $MAJOR_BREAKPOINT;
  }

  @include media-query($_range: above, $_breakpoint: $_breakpoint-wide) {
    $_gutter-x: percentage($_layout-gutter-x-wide / $_content-width-wide);
    $_gutter-y: #{$_layout-gutter-y-wide}px;

    &[class*='_column'] {
      > #{$_container} {
        display: flex;
        flex-wrap: wrap;

        > #{$_item} {
          flex-shrink: 0;
          margin: $_gutter-y 0 0 $_gutter-x;
        }
      }
    }

    @for $i from 1 through $_layout-loop {
      &._column-#{$i}-wide {
        > #{$_container} {
          $_gutter: $_gutter-x * ($i - 1);

          > #{$_item} {
            flex-basis: (100% - $_gutter) / $i;

            &:nth-child(-n+#{$i}) {
              margin-top: 0;
            }

            &:nth-of-type(#{$i}n+1) {
              margin-left: 0;
            }
          }
        }
      }
    }

    &._column-auto-wide {
      > #{$_container} {
        flex-basis: 100% + $_gutter-x;
        margin: -#{$_gutter-y} 0 0 -#{$_gutter-x};

        > #{$_item} {
          max-width: 100% - $_gutter-x;
        }
      }
    }
  }


  // Medium Viewport
  @if $_breakpoint-min and $_breakpoint-max {
    @include media-query($_range: between, $_breakpoint-min: $_breakpoint-min, $_breakpoint-max: $_breakpoint-max) {
      $_gutter-x: percentage($_layout-gutter-x-medium / $_content-width-medium);
      $_gutter-y: #{$_layout-gutter-y-medium}px;

      &[class*='_column'] {
        > #{$_container} {
          display: flex;
          flex-wrap: wrap;

          > #{$_item} {
            flex-shrink: 0;
            margin: $_gutter-y 0 0 $_gutter-x;
          }
        }
      }

      @for $i from 1 through $_layout-loop {
        &._column-#{$i}-medium {
          > #{$_container} {
            $_gutter: $_gutter-x * ($i - 1);

            > #{$_item} {
              flex-basis: (100% - $_gutter) / $i;

              &:nth-child(-n+#{$i}) {
                margin-top: 0;
              }

              &:nth-of-type(#{$i}n+1) {
                margin-left: 0;
              }
            }
          }
        }
      }

      &._column-auto-medium {
        > #{$_container} {
          flex-basis: 100% + $_gutter-x;
          margin: -#{$_gutter-y} 0 0 -#{$_gutter-x};

          > #{$_item} {
            max-width: 100% - $_gutter-x;
          }
        }
      }
    }
  }


  // Narrow Viewport
  @if $_breakpoint-min {
    $_breakpoint-narrow: $_breakpoint-min;
  } @else {
    $_breakpoint-narrow: $MAJOR_BREAKPOINT;
  }

  @include media-query($_range: below, $_breakpoint: $_breakpoint-narrow) {
    $_gutter-x: percentage($_layout-gutter-x-narrow / $_content-width-narrow);
    $_gutter-y: #{$_layout-gutter-y-narrow}px;

    &[class*='_column'] {
      > #{$_container} {
        display: flex;
        flex-wrap: wrap;

        > #{$_item} {
          flex-shrink: 0;
          margin: $_gutter-y 0 0 $_gutter-x;
        }
      }
    }

    @for $i from 1 through $_layout-loop {
      &._column-#{$i}-narrow {
        > #{$_container} {
          $_gutter: $_gutter-x * ($i - 1);

          > #{$_item} {
            flex-basis: (100% - $_gutter) / $i;

            &:nth-child(-n+#{$i}) {
              margin-top: 0;
            }

            &:nth-of-type(#{$i}n+1) {
              margin-left: 0;
            }
          }
        }
      }
    }

    &._column-auto-narrow {
      > #{$_container} {
        flex-basis: 100% + $_gutter-x;
        margin: -#{$_gutter-y} 0 0 -#{$_gutter-x};

        > #{$_item} {
          max-width: 100% - $_gutter-x;
        }
      }
    }
  }
}
