@charset 'utf-8';

// Foundation
// ========================================================================
@import 'foundation/variable';
@import 'foundation/mixin';
@import 'foundation/reset';
@import 'foundation/base';
@import 'foundation/placeholderSelector';

// Structure
// ========================================================================
// Narrow Viewport
@include media-query {
  .str-header {
    > .headerInner {
      > .secondary {
        padding-top: 0;
        position: static;
        z-index: auto;
      }
    }
  }
}

// Module
// ========================================================================

// Unique
// ========================================================================

// Widget
// ========================================================================

