@charset 'utf-8';

// Placeholder-Selector
// ---------------------------------------------------------------------------
// Reset margins of first and last
// ---------------------------------------------
%reset-margin {
  > :first-child {
    margin-top: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}
