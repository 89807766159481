@charset 'utf-8';

// Base
// ---------------------------------------------------------------------------
*,
*::before,
*::after {
  box-sizing: border-box;
}


// Document element
// ---------------------------------------------
html {
  font-size: 62.5%;

  &[lang='ja'] {
    body {
      font-family: $FONT_FAMILY_SANS_JP;
    }
  }

  &[lang='en'] {
    body {
      font-family: $FONT_FAMILY_SANS_EN;
    }
  }
}


// Sections
// ---------------------------------------------
body {
  @extend %reset-margin;
  background-color: $BACKGROUND_COLOR;
  color: $COLOR;
  font-size: $FONT_SIZE_WIDE;
  line-height: $LINE_HEIGHT_WIDE;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}


// Narrow Viewport
@include media-query {
  body {
    font-size: $FONT_SIZE_NARROW;
    line-height: $LINE_HEIGHT_NARROW;
  }
}


// Grouping content
// ---------------------------------------------
[class*='str-'],
[class*='mod-'],
[class*='unq-'] {
  p {
    margin: 0;
    padding: 0;
  }
}

ul,
dl {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol {
  list-style: decimal;
  padding-left: 1.5em;
}

li,
dt,
dd {
  @extend %reset-margin;
  margin: 0;
  padding: 0;
}

figure,
figcaption {
  display: block;
  margin: 0;
}


// Text-level semantics
// ---------------------------------------------
// em,
// strong,
// b {
//   font-style: normal;
//   font-weight: bold;
// }

// strong {
//   color: $IMPORTANT_COLOR;
// }

[lang='ja'] {
  i ,
  em {
    font-family: $FONT_FAMILY_SANS_ITALIC_JP;
  }
}

sub,
sup {
  bottom: -.2em;
}

dfn {
  font-style: normal;
}


// Links
// ---------------------------------------------
a {
  @include transition($_properties: color);
  @include pseudo-class {
    color: $LINK_COLOR;
    text-decoration: underline;
  }

  color: $COLOR;
  text-decoration: none;
}

.contentInner,
[role='main'],
.nav-secondary-01 {
  a {
    @include pseudo-class {
      color: $LINK_COLOR;
      text-decoration: none;
    }

    color: $COLOR;
    text-decoration: underline;
  }
}


// Embed
// ---------------------------------------------
img,
svg,
iframe {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

iframe {
  width: 100%;
}


// Tabular data
// ---------------------------------------------
table {
  border-collapse: collapse;
  table-layout: fixed;
}

th,
td {
  @extend %reset-margin;
}

th {
  text-align: left;
}


// Forms
// ---------------------------------------------

label {
  cursor: pointer;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.str-header {
  legend {
    font-size: 0;
  }
}

[class='mod-form'] {
  input,
  button,
  select,
  textarea,
  [role='button'] {
    appearance: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    max-width: 100%;
    vertical-align: middle;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  input {
    [type='hidden'] {
      display: none;
    }

    &::-ms-clear {
      visibility: hidden;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  select {
    &::-ms-expand {
      display: none;
    }
  }

  textarea {
    overflow-x: hidden; // IE
    overflow-y: auto;   // IE
    resize: vertical;
  }
}

button {
  border: 0;
  padding: 0;
}